import React, { FC, SyntheticEvent, useCallback } from "react";

import { convertValue } from "utils/entities.utils";
import { Select } from "@axin-org/comet";
import { useQuery } from "react-query";
import { useUserSettings } from "hook/useUserSettings";
import { UserSettingProps } from "types/UserSettings";
import { Trans } from "react-i18next";
import { api } from "api/api";
import { tw } from "twind";

async function getAllLangs() {
  const { data } = await api.userSettings.fetchLangs();
  return data;
}

const SelectLang: FC<{}> = props => {
  const { lang: initialLang } = useUserSettings() as UserSettingProps;

  const { data: langs } = useQuery("langs", getAllLangs);

  const onChange = useCallback(
    (e: SyntheticEvent<any>) => {
      const val = convertValue(e);
      const lang = langs && langs.find(l => l.value === val);

      if (lang) {
        api.userSettings
          .updateSelectedLang(lang.baseValue)
          .then(() => {
            window.location.reload();
          })
          .catch(erreur => {
            console.log(erreur);
          });
      }
    },
    [langs]
  );

  return (
    <div className={tw("p-1")}>
      <Trans i18nKey="crm_dashboard_langue">Langue</Trans>
      <Select value={initialLang} name="changeLanguage" onChange={onChange}>
        {langs &&
          langs.map(val => (
            <option key={val.value} value={val.value}>
              {val.label}
            </option>
          ))}
      </Select>
    </div>
  );
};

export default SelectLang;
