import {
  RSQLCriteria,
  Operators,
  RSQLFilterExpression,
  RSQLFilterList
} from "rsql-criteria-typescript";

export type sortOrder = "ASC" | "DESC" | undefined;

export interface GensearchClause {
  field: string;
  operator: RSQLOperator;
  value: any;
  bracket?: "OPEN" | "CLOSE";
  conjunctionWithNext?: "AND" | "OR";
}

export function convertTermByOperator(term: any, operator: string) {
  if (typeof term != "string" || term.includes("%")) return term;
  switch (operator) {
    case "OPER_LIKE_START":
      return `${term}%`;

    case "OPER_LIKE_END":
      return `%${term}`;

    case "OPER_LIKE_ANYWHERE":
      return `%${term}%`;

    default:
      return term;
  }
}

export type RSQLOperator =
  | "OPER_NULL"
  | "OPER_NOT_NULL"
  | "OPER_EQ"
  | "OPER_NE"
  | "OPER_GT"
  | "OPER_LT"
  | "OPER_GE"
  | "OPER_LE"
  | "OPER_LIKE_ANYWHERE"
  | "OPER_LIKE_END"
  | "OPER_LIKE_START"
  | "OPER_IN"
  | "OPER_NOT_IN";
/**
 * converti un string vers un Operator de rsql-criteria-typescript
 *
 * @param {string} operator operator sous forme de string
 * @returns un operator
 */
function stringToOperator(operator: RSQLOperator | string): Operators {
  switch (operator) {
    case "OPER_NULL":
      return Operators.IsNull;
    case "OPER_NOT_NULL":
      return Operators.IsNotNull;
    case "OPER_EQ":
      return Operators.Like;
    case "OPER_NE":
      return Operators.NotEqual;
    case "OPER_GT":
      return Operators.GreaterThan;
    case "OPER_LT":
      return Operators.LessThan;
    case "OPER_GE":
      return Operators.GreaterThanEqualTo;
    case "OPER_LE":
      return Operators.LessThanEqualTo;
    case "OPER_LIKE_ANYWHERE":
      return Operators.Like;
    case "OPER_LIKE_END":
      return Operators.Like;
    case "OPER_LIKE_START":
      return Operators.Like;
    case "OPER_IN":
      return Operators.In;
    case "OPER_NOT_IN":
      return Operators.NotIn;
    default:
      return Operators.Like;
  }
}

export const getRsqlOperator = stringToOperator;

/**
 * Methode qui détermine si on doit ajouter avec un AND ou un OR a la liste des paramètres RSQL
 *
 * @param {RSQLFilterList} filters filtres sur lequels on applique le nouveau critère
 * @param {string} previousConjunction la conjunction que l'on souhaite appliquer
 * @param {(RSQLFilterExpression | RSQLFilterList)} expression l'expression a appliquer
 */
function chooseConjunction(
  filters: RSQLFilterList,
  previousConjunction: string,
  expression: RSQLFilterExpression | RSQLFilterList
) {
  if (previousConjunction === "AND") {
    filters.and(expression);
  } else if (previousConjunction === "OR") {
    filters.or(expression);
  }
}

/**
 * Permet d'initialiser le RSQL criteria
 */
export function initRsqlCriteria() {
  return new RSQLCriteria("q", "order", "size", undefined, "first");
}

/**
 * Permet de transformer une liste de DTO de gensearchCriteria en critère RSQL.
 *
 * @export
 * @param {GensearchClause[]} gensearch liste de critère
 * @returns {RSQLCriteria} critère RSQL
 */
export function transform(gensearch: GensearchClause[]): RSQLCriteria {
  // paramètre lors de la conversion
  const rsql = initRsqlCriteria();

  // variables de boucle
  let previousConjunction = "AND";
  let conjunctionBeforeOpen = "AND";
  let isInsideBracket = false;

  // variable utilisé pour nous permettre d'avoir une gestion des
  // parenthèses
  let listInsideBracket: RSQLFilterList | null = null;

  // compteur
  let count = 0;
  let maxCount = gensearch.length;

  // constructions du RSQL
  for (let crit of gensearch) {
    if (crit.bracket === "OPEN") {
      isInsideBracket = true;
      conjunctionBeforeOpen = previousConjunction;
    }

    if (crit.bracket === "CLOSE") {
      isInsideBracket = false;
    }

    // on crée l'expression en fonction du critère
    const expression = new RSQLFilterExpression(
      crit.field,
      stringToOperator(crit.operator),
      crit.value
    );

    // Comme les critères de recherche peuvent avoir des parenthèses,
    // il y a plusieurs paramètres a prendre en compte :
    // - si on est dans une bracket, on doit ajouter l'expression dans listInsideBracket
    //   ce paramètre correspond aux critères à l'intérieur de la boucle
    // - si on est pas dans un critère, on ajoute directement dans rsql.

    // on est à l'intérieur d'une parenthèse
    if (isInsideBracket) {
      // si il n'existe pas encore, on l'initialise.
      if (listInsideBracket === null) {
        listInsideBracket = new RSQLFilterList();
      }

      chooseConjunction(listInsideBracket, previousConjunction, expression);

      // si on est plus dans la parenthèse, ou que l'on arrive sur le dernier element de la liste
    } else if (
      (!isInsideBracket && listInsideBracket !== null) ||
      (isInsideBracket && count === maxCount - 1 && listInsideBracket !== null)
    ) {
      // on ajoute la dernière expression puis on ajoute la liste à la requête globale
      chooseConjunction(listInsideBracket, previousConjunction, expression);
      chooseConjunction(rsql.filters, conjunctionBeforeOpen, listInsideBracket);

      // on vide la variable
      listInsideBracket = null;

      // si jamais on est pas dans des parenthèse ET
      // que l'on pas initialisé la variable d'ajout d'expression dans les parenthèses
    } else if (!isInsideBracket && listInsideBracket === null) {
      chooseConjunction(rsql.filters, previousConjunction, expression);
    }

    // historique contextuel
    previousConjunction = crit.conjunctionWithNext ? crit.conjunctionWithNext : "AND";
    count++;
  }

  return rsql;
}

const OPERATOR_BY_VALUE = {
  LIKE_END: /^%.*(?<!%)$/g,
  LIKE_START: /^.*%.*$|^%.*%$/g,
  LIKE_ANYWHERE: /^.*%.*%$|^%.*%$/g
};

export function chooseOperatorByValue(value: any): Operators {
  if (typeof value === "number") {
    return Operators.Like;
  } else if (typeof value === "string") {
    if (value.lastIndexOf("%") === 0) {
      return Operators.EndsWith;
    } else if (value.indexOf("%") === value.length - 1) {
      return Operators.StartsWith;
    } else if (value.match(OPERATOR_BY_VALUE.LIKE_ANYWHERE)) {
      return Operators.Like;
    }
  }

  // par défaut : equal
  return Operators.Like;
}

export function chooseOperatorStrByValue(value: any): RSQLOperator {
  // TODO: work on this : work start / end / anywhere doesn't work correctly
  if (typeof value === "number") {
    return "OPER_EQ";
  } else if (typeof value === "string") {
    if (value.match(OPERATOR_BY_VALUE.LIKE_END)) {
      return "OPER_LIKE_END";
    } else if (value.match(OPERATOR_BY_VALUE.LIKE_START)) {
      return "OPER_LIKE_START";
    } else if (value.match(OPERATOR_BY_VALUE.LIKE_ANYWHERE)) {
      return "OPER_LIKE_ANYWHERE";
    }
  }

  // par défaut : equal
  return "OPER_EQ";
}

export function mapToRSQLWithOR(
  filter: Record<string, string> = {},
  sort: Record<string, sortOrder> = {},
  breakRows: string[] = [],
  withLikeByDefault: boolean = false
) {
  return mapToRSQL(filter, sort, breakRows, withLikeByDefault, true);
}

export function mapToRSQL(
  filter: Record<string, string> = {},
  sort: Record<string, sortOrder> = {},
  breakRows: string[] = [],
  withLikeByDefault: boolean = false,
  withOR: boolean = false
): RSQLCriteria {
  const rsql = new RSQLCriteria("q", "order", "size", undefined, "first");

  const keys = Object.keys(filter);
  for (let key of keys) {
    const operator = chooseOperatorByValue(filter[key]);

    let value: any;
    if (typeof filter[key] === "string") {
      value = filter[key].replace(/%/g, operator === Operators.Like ? "*" : "");

      // on souhaite avoir un like par défaut
      if (withLikeByDefault && value.indexOf("*") === -1) {
        value = `*${value}*`;
      }
    } else {
      value = filter[key];
    }

    const expr = new RSQLFilterExpression(key, operator, value);
    if (withOR) {
      rsql.filters.or(expr);
    } else {
      rsql.filters.and(expr);
    }
  }

  mapSortToRSQL(rsql, sort, breakRows);

  return rsql;
}

export function mapSortToRSQL(
  rsql: RSQLCriteria,
  sort: Record<string, sortOrder> = {},
  breakRows: string[] = []
) {
  const sortCopy = { ...sort };

  for (let breakRow of breakRows) {
    const columnBreakRow = breakRow.split(".")[0];
    const direction = sortCopy[columnBreakRow] || "ASC";
    rsql.orderBy.add(breakRow, direction === "ASC" ? "asc" : "desc");
    delete sortCopy[columnBreakRow];
  }

  const sortKeys = Object.keys(sortCopy);
  for (let key of sortKeys) {
    if (sortCopy[key]) {
      rsql.orderBy.add(key, sortCopy[key] === "ASC" ? "asc" : "desc");
    }
  }
}

export class RSQLFilterExpressionString extends RSQLFilterExpression {
  constructor(public filterString: string) {
    super("null", Operators.Like, undefined);
    this.build = this.build.bind(this);
  }

  public build(): string {
    return encodeURIComponent(this.filterString || "");
  }
}

export interface GSOR {
  type: "GSOR";
  nodes: GS[];
}

export interface GSAND {
  type: "GSAND";
  nodes: GS[];
}

export interface GSComparison {
  type: "GSCOMPARISON";
  field: string;
  operator: RSQLOperator;
  value: any;
}

export type GS = GSOR | GSAND | GSComparison;

export const GSBuilder = {
  AND(...nodes: GS[]): GS {
    return {
      type: "GSAND",
      nodes
    };
  },
  OR(...nodes: GS[]): GS {
    return {
      type: "GSOR",
      nodes
    };
  },
  Comparison(field: string, operator: RSQLOperator, value: any): GS {
    return {
      type: "GSCOMPARISON",
      field,
      operator,
      value
    };
  },
  toFilter: nodeToRSQLFilter,
  visit: visitNode
};

/**
 * meh
 * @param node une node random
 */
function nodeToRSQLFilter(node: GS, term?: string): RSQLFilterList {
  function processNodes(nodes: GS[], operator: "AND" | "OR") {
    let filterList: RSQLFilterList[] = [];
    for (let node of nodes) {
      const rsql = nodeToRSQLFilter(node, term);
      filterList.push(rsql);
    }
    const rsql = new RSQLFilterList();
    switch (operator) {
      case "AND":
        for (let filter of filterList) rsql.and(filter);
        break;
      case "OR":
        for (let filter of filterList) rsql.or(filter);
        break;
    }
    return rsql;
  }

  const rsql = new RSQLFilterList();
  switch (node.type) {
    case "GSOR":
      return processNodes(node.nodes, "OR");
    case "GSAND":
      return processNodes(node.nodes, "AND");
    case "GSCOMPARISON":
      const isTerm = term !== undefined;
      const filter = new RSQLFilterExpression(
        node.field,
        stringToOperator(node.operator),
        isTerm && (node.value === null || node.value === "")
          ? convertTermByOperator(term ?? "", node.operator)
          : node.value
      );
      rsql.and(filter);
      return rsql;
  }
}

export type GSVisitorCallback<T> = {
  And: (param: GSAND, visitor: GSVisitorCallback<T>) => T;
  Or: (param: GSOR, visitor: GSVisitorCallback<T>) => T;
  Comparison: (param: GSComparison, visitor: GSVisitorCallback<T>) => T;
};

function visitNode<T>(node: GS, visitor: GSVisitorCallback<T>): T | undefined {
  switch (node.type) {
    case "GSOR":
      return visitor.Or(node, visitor);
    case "GSAND":
      return visitor.And(node, visitor);
    case "GSCOMPARISON":
      return visitor.Comparison(node, visitor);
  }
}
