import React, { FC } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { tw } from "twind";

//export type ButtonLinkProps = Assign<RouterLinkProps, CLinkProps>;

/*const ButtonLink: ForwardRef<HTMLAnchorElement, ButtonLinkProps> = React.forwardRef(function Input(
  props,
  ref
) {
  return <CLink ref={ref} as={RouterLink} {...props} />;
});
*/
const ButtonLink: FC<React.PropsWithoutRef<NavLinkProps> &
  React.RefAttributes<HTMLAnchorElement> & { className?: string }> = props => {
  return (
    <NavLink to={props.to} style={props.style} className={tw(props.className)}>
      {props.children}
    </NavLink>
  );
};

export default ButtonLink;
