import React from "react";
import ReactDOM from "react-dom";
import ToastManager from "./ToastManager";
import { Notification, NotificationGroup } from "types/Notification";
import { uuidv4 } from "component/utils/uuid.utils";

class Toaster {
  private notifyHandler?: (notification: Notification) => void;
  private closeAllHandler?: () => void;

  constructor() {
    const container = document.createElement("div");
    container.setAttribute("data-octal-toaster-container", "");

    document.body.append(container);

    ReactDOM.render(
      <ToastManager bindNotify={this.bindNotify} bindCloseAll={this.bindCloseAll} />,
      container
    );
  }

  private bindNotify = (handler: any) => {
    this.notifyHandler = handler;
  };

  private bindCloseAll = (handler: any) => {
    this.closeAllHandler = handler;
  };

  public success = (title: string) => {
    this.notify({
      id: uuidv4(),
      group: NotificationGroup.DEFAULT,
      intent: "SUCCESS",
      priority: "NORMAL",
      title: title,
      createdAt: new Date().toISOString()
    });
  };

  public notify = (notification: Notification) => {
    this.notifyHandler && this.notifyHandler(notification);
  };

  public closeAll = () => {
    this.closeAllHandler && this.closeAllHandler();
  };
}

export default new Toaster();
