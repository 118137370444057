import React, { FC, useState, useMemo } from "react";
import { Stack, Columns, Column, Label, Button } from "@axin-org/comet";
import {
  format,
  addDays,
  parse,
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds
} from "date-fns";
import { useQuery } from "react-query";
import { fetchTasks } from "component/calendar/FullCalendarContainer";
import { Task } from "types/Scheduler";
import { Link } from "react-router-dom";
import { getAccoIcon } from "component/list/card/ActionCommercialeCard";
import ButtonLink from "component/menu/ButtonLink";
import { useUserSettings } from "hook/useUserSettings";
import { UserSettingProps } from "types/UserSettings";
import { tw } from "twind";
import { css } from "twind/css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let today = setMilliseconds(setSeconds(setMinutes(setHours(new Date(), 0), 0), 0), 0);

function upperFirstletter(text: string) {
  if (text === "") return text;
  return text[0].toUpperCase() + text.slice(1);
}

const DayMark: FC<{
  date: Date;
  userLang: string;
  isChosen?: boolean;
  onClick(): void;
}> = props => {
  const dayFormat = new Intl.DateTimeFormat(props.userLang, { weekday: "short" });

  return (
    <Column className="flex justify-center" width="1/6">
      <Button onClick={props.onClick} variant="text">
        <Stack>
          <div className={tw("text-gray-800")}>{dayFormat.format(props.date)}</div>
          <div
            className={tw("rounded-full flex justify-center p-1", props.isChosen && "bg-blue-300")}
          >
            {format(props.date, "DD")}
          </div>
        </Stack>
      </Button>
    </Column>
  );
};

const MiniTaskCard: FC<{ task: Task }> = props => {
  const { task } = props;

  const { icon, intent } = useMemo(
    () => getAccoIcon(parse(task.end as string), task.extendedProps.statut),
    [task.extendedProps.statut, task.end]
  );
  return (
    <div className={tw("rounded pl-2", `bg-${task.backgroundColor}`)}>
      <Link
        to={`/actionCommerciale/${encodeURIComponent(task.id as string)}`}
        style={{ textDecoration: "none", color: "white" }}
      >
        <Columns>
          <Column width="1/5">
            <Stack>
              <div>{format(parse(task.start as string), "HH:mm")}</div>
              <div>{format(parse(task.end as string), "HH:mm")}</div>
            </Stack>
          </Column>
          <Column width="4/5">
            <div
              className="overflow-hidden"
              style={{ maxHeight: "5rem" }}
              dangerouslySetInnerHTML={{ __html: task.title }}
            />
          </Column>
          <Button
            variant="outlined"
            className={tw(
              "px-2",
              css({ borderColor: task.backgroundColor, borderRadius: "0 5px 5px 0" })
            )}
          >
            {icon && <FontAwesomeIcon icon={icon} className={tw(intent)} />}
          </Button>
        </Columns>
      </Link>
    </div>
  );
};

const HomeAgenda: FC<{}> = props => {
  const { id: user, lang: userLang } = useUserSettings() as UserSettingProps;

  const titleFormat = userLang
    ? new Intl.DateTimeFormat(userLang, {
        month: "long",
        year: "numeric"
      })
    : null;

  // Index sur les jours 0 = today
  const [index, setIndex] = useState<number>(0);
  const pagination: { start: Date; end: Date } = useMemo(() => {
    return { start: addDays(today, index), end: addDays(today, index + 1) };
  }, [index]);

  const { data: tasks } = useQuery([user, pagination.start, pagination.end], () =>
    fetchTasks(user, pagination.start, pagination.end)
  );

  return (
    <div className={tw("bg-white py-2 min-h-[10rem]")}>
      <Columns>
        <Column width="11/12">
          <Label className="pl-2 text-xl">
            {titleFormat ? upperFirstletter(titleFormat.format(today)) : ""}
          </Label>
        </Column>

        <Column width="1/12">
          <div className={tw("pr-1 flex justify-end w-full")}>
            <ButtonLink
              to={`/create/actionCommerciale?accoDtPrevueJour=${pagination.start}`}
              className="py-0 px-1"
            >
              <FontAwesomeIcon icon="plus" />
            </ButtonLink>
          </div>
        </Column>
      </Columns>
      <Columns className="pb-2">
        {userLang && (
          <>
            <DayMark
              date={addDays(today, -1)}
              userLang={userLang}
              isChosen={index === -1}
              onClick={() => setIndex(-1)}
            />
            <DayMark
              date={today}
              userLang={userLang as string}
              isChosen={index === 0}
              onClick={() => setIndex(0)}
            />
            <DayMark
              date={addDays(today, 1)}
              userLang={userLang}
              isChosen={index === 1}
              onClick={() => setIndex(1)}
            />
            <DayMark
              date={addDays(today, 2)}
              userLang={userLang}
              isChosen={index === 2}
              onClick={() => setIndex(2)}
            />
            <DayMark
              date={addDays(today, 3)}
              userLang={userLang}
              isChosen={index === 3}
              onClick={() => setIndex(3)}
            />
            <DayMark
              date={addDays(today, 4)}
              userLang={userLang}
              isChosen={index === 4}
              onClick={() => setIndex(4)}
            />
            <DayMark
              date={addDays(today, 5)}
              userLang={userLang}
              isChosen={index === 5}
              onClick={() => setIndex(5)}
            />
          </>
        )}
      </Columns>
      <Stack gap={1}>{tasks && tasks.map(t => <MiniTaskCard key={t.id} task={t} />)}</Stack>
    </div>
  );
};

export default HomeAgenda;
