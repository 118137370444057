import React, { FC } from "react";
import { IndicateurData } from "types/IndicateurData";
import { Trans } from "react-i18next";
import { tw } from "twind";

const BotTitle: FC<IndicateurData> = props => {
  return (
    <div
      title={props.tooltip}
      className={tw("h-24 text-center rounded border-1 border-blue-200 text-blue-400")}
    >
      <div className={tw("text-3xl h-3/5 p-3", `text-[${props.color}]`)}>
        {props.value ? props.value : 0}
      </div>
      <div className={tw("text-black text-sm h-2/5")}>
        <div className={tw("self-end")}>
          <Trans i18nKey={props.title} />
        </div>
      </div>
    </div>
  );
};

export default BotTitle;
