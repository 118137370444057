import React, { FC, useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import RouterRoot from "./App";
import * as serviceWorker from "./serviceWorker";

import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";

import i18n, { initI18n } from "utils/i18n";

// charge les icones font-awesome
import "./iconLibrary";
import auth, { initAuth, reconnect } from "auth";
import FullPageLoader from "component/utils/FullPageLoader";
import { useInitializeUserSettings, UserContextProvider } from "hook/useUserSettings";
import { api } from "api/api";
import { PUBLIC_URL } from "./customGlobal";

import { tw, setup } from "twind";
import twindConfig from "./twind.config";

setup(twindConfig);

type STATUS_AUTH = "INITIAL" | "LOGGED" | "ERROR";

const AuthLoader: FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [status, setStatus] = useState<STATUS_AUTH>(auth.authenticated ? "LOGGED" : "INITIAL");

  const setUserInfo = useInitializeUserSettings();

  useEffect(() => {
    if (status === "INITIAL") {
      sessionStorage.setItem("userLang", "fr");

      initAuth()
        .then(authenticated => {
          if (!authenticated) {
            setStatus("ERROR");
            return;
          }

          initI18n();

          api.userSettings.fetchUserSettings().then(response => {
            try {
              setUserInfo(response.data);
              // mise en session storage pour être utilisé dans i18n.ts
              sessionStorage.setItem("userLang", response.data.lang);
              i18n.changeLanguage(response.data.lang);
              setStatus("LOGGED");
            } catch {
              // on arrive parfois ici, yollo catch pour éviter d'avoir la page blanche.
              setStatus("ERROR");
            }
          });
        })
        .catch(() => {
          setStatus("ERROR");
        });
    }
  }, [setUserInfo, status]);

  switch (status) {
    case "INITIAL":
      return null;
    case "ERROR":
      return (
        <div style={{ textAlign: "center", marginTop: "1em" }}>
          <div className="title is-3">Authentication failed</div>
          {/* <AuthenticationFailed /> */}
          <div>
            <button className="button  is-link" onClick={reconnect}>
              reconnect
            </button>
          </div>
        </div>
      );
    case "LOGGED":
      return <Root />;
  }
};

const Root: FC = () => {
  return (
    <React.StrictMode>
      <React.Suspense
        fallback={
          <div className={tw("h-screen")}>
            <FullPageLoader />
          </div>
        }
      >
        <I18nextProvider i18n={i18n} defaultNS="crm">
          <RouterRoot />
        </I18nextProvider>
      </React.Suspense>
    </React.StrictMode>
  );
};

const rootElement = createRoot(document.getElementById("root") as HTMLElement);

rootElement.render(
  <UserContextProvider>
    <AuthLoader />
  </UserContextProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
