import React, { FC } from "react";
import { NotificationIntent } from "types/Notification";
import { IconName } from "@fortawesome/pro-solid-svg-icons";
import { Columns, Column, Button } from "@axin-org/comet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { tw } from "twind";
import { css } from "twind/css";

function getIconForIntent(intent: NotificationIntent): { icon: IconName; color: string } {
  switch (intent) {
    case "SUCCESS":
      return { icon: "check-circle", color: "success" };
    case "WARNING":
      return { icon: "exclamation-triangle", color: "warning" };
    case "DANGER":
      return { icon: "exclamation-circle", color: "danger" };

    case "INFO":
      return { icon: "info", color: "primary" };
    case "DEFAULT":
    default:
      return { icon: "info", color: "black" };
  }
}

const Alert: FC<React.PropsWithChildren<{
  intent: NotificationIntent;
  title: string;
  onRemove(): void;
}>> = props => {
  const iconInfo = getIconForIntent(props.intent);
  return (
    <div
      className="toast-alert"
      data-intent={props.intent.toLowerCase()}
      style={{ borderRadius: 5 }}
    >
      <div>
        <Columns>
          <Column width="narrow" className="pr-3">
            <FontAwesomeIcon icon={iconInfo.icon} className={tw(css({ color: iconInfo.color }))} />
          </Column>
          <Column>
            <header className="toast-alert--header">{props.title}</header>
          </Column>
          <Column width="narrow" className="pl-3">
            <Button variant="text" onClick={props.onRemove}>
              <FontAwesomeIcon icon="times" color="#718096" />
            </Button>
          </Column>
        </Columns>
      </div>

      <div>
        {typeof props.children === "string" ? (
          <span
            dangerouslySetInnerHTML={{ __html: props.children }}
            className="toast-alert--content"
          />
        ) : (
          props.children
        )}
      </div>
    </div>
  );
};

export default Alert;
