import React, { FC, useState, useEffect, useCallback } from "react";
import {
  Modal,
  Input,
  useRegisterProcessus,
  useStableProcessusId,
  ProcessusDefinitionNature,
  useProcesssuDelayedContext,
  Button
} from "@axin-org/comet";
import { convertValue } from "utils/entities.utils";
import { ProcessusNatureValue, Pojo, GS } from "@axin-org/api";
import { AdvancedProcessusDefinition } from "types/Processus";
import { api } from "api/api";
import { queryClient } from "App";
import ProcessussModal from "./menu/ProcessusModal";
import { useTranslation } from "react-i18next";

function getFields(node: GS, listField: string[]) {
  if (node.type === "GSCOMPARISON") {
    listField.push(node.field);
  } else {
    for (const underNode of node.nodes) {
      getFields(underNode, listField);
    }
  }
  return listField;
}

async function initParamAvance(
  tableName: string,
  entityId: string | null,
  processId: string,
  nature: ProcessusNatureValue,
  mode: "rapide" | "apercu",
  setParamAddDefinition: (def: AdvancedProcessusDefinition) => void
) {
  try {
    const { data } = await queryClient.fetchQuery(
      ["process", processId, tableName, entityId ? [entityId] : null, mode],
      () =>
        api.processus.initProcessusAdvanced(
          "CRM",
          processId,
          entityId ? [{ id: entityId } as Pojo] : []
        )
    );

    if (data.definitions) {
      const result: AdvancedProcessusDefinition = {
        id: processId,
        definition: data,
        tableName,
        nature
      };

      for (const etape of result.definition.definitions) {
        for (const group of etape) {
          for (const compo of group.compos) {
            if (compo.additionalClause) {
              (compo as any).searchFields = getFields(compo.additionalClause, []);
              compo.additionalClause = undefined;
            }
          }
        }
      }

      setParamAddDefinition(result);
    }
  } catch {
    // rien l'ereur est déjà gérée
  }
}

const ProcessusDelayedModal: FC<{}> = props => {
  const {
    tableName,
    advanced,
    callback,
    definitionId,
    editionType,
    label,
    onClose,
    sjmoCode,
    type,
    selected
  } = useProcesssuDelayedContext();
  const [t] = useTranslation();
  const [date, setDate] = useState<string>("");
  const [paramAddDefinition, setParamAddDefinition] = useState<AdvancedProcessusDefinition | null>(
    null
  );

  const [
    managerState,
    { register: registerProcessus, reset: resetProcessus }
  ] = useRegisterProcessus();
  const stableID = useStableProcessusId(definitionId, selected, undefined);

  const machineId = managerState.context.info[stableID];
  const currentProcessusMachine = managerState.context.processus[machineId ?? ""];
  const hasProcessusStateSpawned =
    currentProcessusMachine !== undefined &&
    !currentProcessusMachine.getSnapshot().matches("error") &&
    !currentProcessusMachine.getSnapshot().matches("done");

  useEffect(() => {
    if (hasProcessusStateSpawned) {
      onClose();
    }
  }, [hasProcessusStateSpawned, onClose]);

  const openAdvanced = useCallback(
    (
      sjmoCode: string,
      definitionId: string,
      definitionType: string,
      selected: Record<string, any>[],
      date: string,
      editionType: "rapide" | "apercu"
    ) => {
      initParamAvance(
        tableName,
        selected.length > 0 ? selected[0].id : null,
        definitionId,
        definitionType === "traitement" ? "TRAIT" : "EDITS",
        editionType,
        setParamAddDefinition
      );
    },
    [tableName]
  );

  const launchProcess = useCallback(() => {
    if (date) {
      if (currentProcessusMachine !== undefined) {
        const snapshot = currentProcessusMachine.getSnapshot();

        if (snapshot.matches("error")) {
          resetProcessus(definitionId, selected);
        } else if (!snapshot.matches("done")) {
          return;
        }
      }

      if ((type === "traitement" && advanced) || (type === "edition" && advanced)) {
        openAdvanced(sjmoCode, definitionId, type, selected as any, date, editionType);
        onClose();
        return;
      }

      registerProcessus(
        {
          module: sjmoCode,
          compositeID: definitionId,
          type: type as ProcessusDefinitionNature,
          selected: selected ?? [],
          executeAt: date,
          editionType: editionType,
          label: label
        },
        callback
      );
    }
  }, [
    advanced,
    callback,
    currentProcessusMachine,
    date,
    definitionId,
    editionType,
    label,
    onClose,
    openAdvanced,
    registerProcessus,
    resetProcessus,
    selected,
    sjmoCode,
    type
  ]);

  const onSubmit = useCallback(() => {
    if (advanced) {
      openAdvanced(
        sjmoCode,
        definitionId,
        type,
        selected as Record<string, any>[],
        date,
        editionType
      );
    } else {
      launchProcess();
    }
  }, [
    advanced,
    date,
    definitionId,
    editionType,
    launchProcess,
    openAdvanced,
    selected,
    sjmoCode,
    type
  ]);

  const onChange = useCallback(
    (ctrlKey: string, value: string) => {
      if (paramAddDefinition !== null) {
        const def: AdvancedProcessusDefinition = { ...paramAddDefinition };
        const values = { ...def.definition.data.paramsAdd[0] };
        values[ctrlKey] = value;
        def.definition.data.paramsAdd = [values];
        setParamAddDefinition(def);
      }
    },
    [paramAddDefinition]
  );

  return (
    <>
      <Modal isOpen={true} onOpenChange={onClose} withOverlay={true}>
        <Input type="time" value={date} onChange={e => setDate(convertValue(e))} id="pickAnHour" />
        <Button onClick={onSubmit} intent="success">
          {t("crm_valider")}
        </Button>
        <Modal.Close>
          <Button intent="danger"> {t("crm_annuler")} </Button>
        </Modal.Close>
      </Modal>
      {paramAddDefinition && (
        <ProcessussModal
          isOpen={paramAddDefinition !== null}
          definition={paramAddDefinition}
          onDismiss={() => setParamAddDefinition(null)}
          onValueChange={onChange}
          refresh={() => Promise.resolve({}) as any}
        />
      )}
    </>
  );
};

export default ProcessusDelayedModal;
