import React, { FC } from "react";
import { Loader } from "@axin-org/comet";
import { tw } from "twind";

const FullPageLoader: FC<{}> = props => {
  return (
    <div className={tw("flex justify-center h-full items-center")}>
      <Loader /*css={{ "> div": { bg: "primary" } }}*/ />
    </div>
  );
};

export default FullPageLoader;
