import React, { FC } from "react";
import { Column, Columns, Stack } from "@axin-org/comet";

import DashboardHeader from "component/menu/DashboardHeader";
import Indicateur from "component/indicateur/Indicateur";
import HomeAgenda from "component/menu/HomeAgenda";
import { AutoSizer } from "react-virtualized";
import { dashBoardHeader } from "const/size";
import ButtonLink from "component/menu/ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { tw } from "twind";

const urls = [
  { label: "crm_accueil_link_create_contact", url: "/create/clientContact" },
  { label: "crm_accueil_link_create_devis", url: "/create/devisClient" }
];

const Accueil: FC<{}> = () => {
  return (
    <AutoSizer disableWidth>
      {({ height }) => (
        <Stack gap={2} style={{ height }}>
          <DashboardHeader urls={urls} titleKey="crm_titre_accueil" />

          <Stack gap={2} className="overflow-y-auto" style={{ height: height - dashBoardHeader }}>
            <Columns gap={2}>
              <Column width="1/12">
                <ButtonLink
                  to="/search/action?term="
                  className={tw(
                    "bg-blue-700 bg(blue-700 hover:blue-500 focus:blue-500) text-white w-full py-2 rounded"
                  )}
                >
                  <FontAwesomeIcon icon="magnifying-glass" className={tw("self-center w-full")} />
                </ButtonLink>
              </Column>
              <Column width="11/12">
                <Indicateur
                  viewName="vjCrmIndAccueilHaut"
                  template="LEFT_TITLE"
                  title="crm_accueil_haut"
                  filterUser
                />
              </Column>
            </Columns>

            <HomeAgenda />
          </Stack>
        </Stack>
      )}
    </AutoSizer>
  );
};

export default Accueil;
