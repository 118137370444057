import { FormElement } from "@axin-org/comet";
import { useTranslation } from "react-i18next";
import { GS, initRsqlCriteria, RSQLFilterExpressionString, GSBuilder } from "utils/query.utils";
import { RSQLFilterList, RSQLFilterExpression, Operators } from "rsql-criteria-typescript";
import { api } from "api/api";
import { FETCH_SIZE } from "constant/api.constant";
import { useMemo } from "react";

function fetchMoreFactory(
  tableName: string,
  q: string | null,
  defaultFilter: GS | null,
  searchFields: string[]
) {
  return async (term: string, start: number, size: number) => {
    const rsql = initRsqlCriteria();
    if (q != null) {
      rsql.filters.and(new RSQLFilterExpressionString(q));
    }

    if (defaultFilter) {
      rsql.filters.and(GSBuilder.toFilter(defaultFilter));
    }

    if (searchFields && searchFields.length > 0 && term && term.length > 0) {
      const rsqlList = new RSQLFilterList();
      for (let field of searchFields) {
        rsqlList.or(new RSQLFilterExpression(field, Operators.StartsWith, term));
      }
      rsql.filters.and(rsqlList);
    }

    const { data } = await api.entities.findAll({
      tableName: tableName,
      filter: rsql.build(),
      first: start,
      size: FETCH_SIZE,
      includeJoinParent: false
    });

    return data;
  };
}

function fetchOneFactory(tableName: string) {
  return async (id: string) => {
    const { data } = await api.entities.findOne({ tableName, id });
    return data;
  };
}

export function useDefineGsFactory() {
  const [t] = useTranslation();
  const loadMoreLabel = t("crm_charger_plus");
  return (props: {
    column: string;
    wvi: boolean;
    label: string;
    joinTableName: string;
    searchFields: string[];
    mandatory?: boolean;
    additionalClause?: GS;
  }) => {
    const queryKey = [props.joinTableName];
    const fetchMore = fetchMoreFactory(
      props.joinTableName,
      null,
      props.additionalClause ? props.additionalClause : null,
      props.searchFields ? props.searchFields : []
    );
    const fetchOne = fetchOneFactory(props.joinTableName);
    return {
      column: props.column,
      typeCompo: "GS",
      wvi: props.wvi,
      mandatory: props.mandatory,
      queryKey,
      loadMoreLabel,
      label: props.label,
      fetchMore,
      fetchOne
    };
  };
}

export default function useDefineGs(props: {
  originTableName: string;
  column: string;
  wvi: boolean;
  joinTableName: string;
  searchFields: string[];
  mandatory?: boolean;
  additionalClause?: GS;
}): FormElement {
  const [t] = useTranslation(props.originTableName);
  return useMemo(() => {
    const queryKey = [props.joinTableName];
    const loadMoreLabel = t("crm_charger_plus");
    const label = t(`${props.originTableName}_${props.column}`);
    const fetchMore = fetchMoreFactory(
      props.joinTableName,
      null,
      props.additionalClause ? props.additionalClause : null,
      props.searchFields ? props.searchFields : []
    );
    const fetchOne = fetchOneFactory(props.joinTableName);
    return {
      column: props.column,
      typeCompo: "GS",
      wvi: props.wvi,
      mandatory: props.mandatory,
      queryKey,
      loadMoreLabel,
      label,
      fetchMore,
      fetchOne
    };
  }, [
    props.additionalClause,
    props.column,
    props.joinTableName,
    props.mandatory,
    props.originTableName,
    props.searchFields,
    props.wvi,
    t
  ]);
}
