import { SyntheticEvent } from "react";
import { format, parse, min as minDate, max as maxDate } from "date-fns";
import { Pojo, WhenValidateItemResult } from "types/Generic";
import { queryClient } from "App";
import { api } from "api/api";

/**
 * Permet de convertir les valeurs d'un element HTML vers la bonne valeur
 * @param event event HTML
 */
export function convertValue(event: SyntheticEvent<any>, useLocalDate: boolean = false) {
  // on évite d'avoir des string vide pour les boolean, number ou date
  if (event.currentTarget.value === undefined || event.currentTarget.value.length === 0) {
    return null;
  }

  switch (event.currentTarget.type) {
    case "checkbox":
      return event.currentTarget.checked;

    case "number": {
      let valueNumber: number = event.currentTarget.valueAsNumber;
      let min = event.currentTarget.min ? Number.parseFloat(event.currentTarget.min) : null;
      let max = event.currentTarget.max ? Number.parseFloat(event.currentTarget.max) : null;

      if (min !== null) {
        valueNumber = Math.max(min, valueNumber);
      }

      if (max !== null) {
        valueNumber = Math.min(max, valueNumber);
      }

      // si jamais on a pas le droit de mettre de nombre à virgule, on faire un round sur le nombre
      let maxValDB: string = event.currentTarget.dataset.maxDb;
      let shouldParseToInt = maxValDB ? maxValDB.indexOf(".") === -1 : false;
      return shouldParseToInt && valueNumber ? Math.round(valueNumber) : valueNumber;
    }

    // peut-être que c'est quelque chose que l'on
    // souhaitera faire plus tard
    case "date":
    case "datetime-local": {
      let dateValue = parse(event.currentTarget.value);
      let min = event.currentTarget.min ? parse(event.currentTarget.min) : null;
      let max = event.currentTarget.max ? parse(event.currentTarget.max) : null;

      if (min !== null) {
        dateValue = maxDate(min, dateValue);
      }

      if (max !== null) {
        dateValue = minDate(max, dateValue);
      }

      const convertedValue = useLocalDate ? format(dateValue, "YYYY-MM-DD") : format(dateValue);
      return convertedValue;
    }

    default:
      // if (event.currentTarget.dataset.dataCustomDecimalLimit) {
      //   return event.currentTarget.valueAsNumber;
      // } else {
      return event.currentTarget.value;
    // }
  }
}

/**
 * Méthode qui permet de valider une entité (WVI) en base.
 * Elle appelle l'api et s'occupe de filtrer le message d'erreur si jamais
 * l'appel plante.
 *
 * @param sjmoCode code module
 * @param tableName nom de la table
 * @param ctrlKey colonne d'origine de la modif
 * @param entity entité à valider
 * @param onSuccess fonction lorsque l'appel est correct
 * @param onError fonction lorsque la validation retourne une erreur
 */
// export function validatePojo(
//   tableName: string,
//   ctrlKey: string,
//   entity: Pojo,
//   onSuccess: (success: AxiosResponse<WhenValidateItemResult<Pojo>>) => void,
//   onError: (error: WhenValidateItemResult<Pojo>) => void
// ) {
//   whenValidateItem(tableName, ctrlKey, entity)
//     .then(res => {
//       onSuccess(res);
//     })
//     .catch((res: AxiosError) => {
//       const data: WhenValidateItemResult<Pojo> = res.response && res.response.data;

//       if (data && typeof data === "object" && data.message.target === "FIELD") {
//         onError(data);
//       } else {
//         const isExistingEntity = entity.id !== null || entity.id !== undefined;
//         console.error(
//           `uncatched exception with validatePojo with ${tableName} on the key ${ctrlKey}. ${
//             isExistingEntity ? `Entity ID = ${entity.id}` : ""
//           }`
//         );
//       }
//     });
// }
export async function validatePojo(
  tableName: string,
  ctrlKey: string,
  entity: Pojo,
  onSuccess: (success: WhenValidateItemResult<Pojo>) => void,
  onError: (error: WhenValidateItemResult<Pojo>) => void
) {
  try {
    const { data } = await queryClient.fetchQuery([tableName, ctrlKey, entity], () =>
      api.entities.whenValidateItem("CRM", tableName, ctrlKey, entity)
    );
    onSuccess(data);
  } catch (err) {
    const data: WhenValidateItemResult<Pojo> = (err as any).response && (err as any).response.data;

    if (data && typeof data === "object" && data.message.target === "FIELD") {
      onError(data);
    } else {
      const isExistingEntity = entity.id !== null || entity.id !== undefined;
      console.error(
        `uncatched exception with validatePojo with ${tableName} on the key ${ctrlKey}. ${
          isExistingEntity ? `Entity ID = ${entity.id}` : ""
        }`
      );
    }
  }
}

/**
 * Retourne une map de pojo indexé par index
 * @param pojos liste de pojo
 */
export function listAsMapOfIndex<T>(pojos: T[], startAt: number = 0) {
  return listToMap<T>(pojos, startAt);
}
export function pojoListToMapOfIndex(pojos: Pojo[], startAt: number = 0) {
  return listToMap(pojos, startAt, (el) => el.id);
}
export function listToMap<T>(
  list: T[] = [],
  startAt: number = 0,
  getter: (el: T) => any = (el: T) => el
): Record<string, T> {
  let map: any = {};

  for (let index = 0; index < list.length; index++) {
    map[index + startAt] = getter(list[index]);
  }

  return map;
}

export function mapToList<T>(map: Record<string, T>): T[] {
  const keys = Object.keys(map);
  let array: T[] = new Array(keys.length);

  for (let key of keys) {
    const index = parseInt(key, 10);
    array[index] = map[key];
  }

  return array;
}

export function mapToUnorderedList<T>(map: Record<string, T>): T[] {
  const keys = Object.keys(map);
  let array: T[] = [];

  for (let key of keys) {
    array.push(map[key]);
  }

  return array;
}

export async function findEntities(props: {
  tableName: string;
  filter: string;
}): Promise<Record<string, any>[]> {
  const { tableName, filter } = props;
  const { data } = await api.entities.findAll({ tableName, filter: `q=${filter}` });
  return data.data as Record<string, any>[];
}
