import React, { FC, useState, useCallback } from "react";
import { Menu, Loader, ProcessusProvider, ProcessusMenu } from "@axin-org/comet";
import { QueryObserverResult, RefetchOptions } from "react-query";
import { AdvancedProcessusDefinition } from "types/Processus";
import { Trans, useTranslation } from "react-i18next";
import { Pojo } from "types/Generic";
import ProcessussModal from "./ProcessusModal";
import { GS } from "utils/query.utils";
import { queryClient } from "App";
import { api } from "api/api";
import { findEntities } from "utils/entities.utils";
import { getProcessusDefinition } from "component/utils/Processus";
import ProcessusDelayedModal from "component/ProcessuDelayedModal";
import ProcessusConfirmationModal from "component/ProcessusConfirmationModal";
import { ProcessusNatureValue } from "@axin-org/api";
import { tw } from "twind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

export interface Shortcut {
  label: string;
  url: string;
}

export interface ProcessusButtonProps {
  tableName: string;
  entityId: string;
  urls?: Shortcut[];
  refresh?: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<Pojo, unknown>>;
}

function getFields(node: GS, listField: string[]) {
  if (node.type === "GSCOMPARISON") {
    listField.push(node.field);
  } else {
    for (const underNode of node.nodes) {
      getFields(underNode, listField);
    }
  }
  return listField;
}

async function initParamAvance(
  tableName: string,
  entityId: string | null,
  processId: string,
  nature: ProcessusNatureValue,
  mode: "rapide" | "apercu",
  setParamAddDefinition: (def: AdvancedProcessusDefinition) => void,
  setLoader: (show: boolean) => void,
  refresh?: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<Pojo, unknown>>
) {
  setLoader(true);
  try {
    const { data } = await queryClient.fetchQuery(
      ["process", processId, tableName, entityId ? [entityId] : null, mode],
      () =>
        api.processus.initProcessusAdvanced(
          "CRM",
          processId,
          entityId ? [{ id: entityId } as Pojo] : []
        )
    );

    if (data.definitions) {
      const result: AdvancedProcessusDefinition = {
        id: processId,
        definition: data,
        tableName,
        nature
      };

      for (const etape of result.definition.definitions) {
        for (const group of etape) {
          for (const compo of group.compos) {
            if (compo.additionalClause) {
              (compo as any).searchFields = getFields(compo.additionalClause, []);
              compo.additionalClause = undefined;
            }
          }
        }
      }

      setParamAddDefinition(result);
    } else if (refresh) {
      refresh();
    }
  } catch {
    // rien l'ereur est déjà gérée
  }
  setLoader(false);
}

const ProcessusButton: FC<ProcessusButtonProps> = props => {
  const navigate = useNavigate();
  const { t: tComet } = useTranslation("comet");
  const [paramAddDefinition, setParamAddDefinition] = useState<AdvancedProcessusDefinition | null>(
    null
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const onChange = useCallback(
    (ctrlKey: string, value: string) => {
      if (paramAddDefinition !== null) {
        const def: AdvancedProcessusDefinition = { ...paramAddDefinition };
        const values = { ...def.definition.data.paramsAdd[0] };
        values[ctrlKey] = value;
        def.definition.data.paramsAdd = [values];
        setParamAddDefinition(def);
      }
    },
    [paramAddDefinition]
  );

  const openAdvanced = useCallback(
    (
      sjmoCode: string,
      definitionId: string,
      definitionType: "traitement" | "edition",
      selected: Record<string, any>[],
      editionType: "rapide" | "apercu",
      onAfterSaveContext: () => void
    ) => {
      setMenuOpen(false);
      initParamAvance(
        props.tableName,
        selected.length > 0 ? selected[0].id : null,
        definitionId,
        definitionType === "traitement" ? "TRAIT" : "EDITS",
        editionType,
        setParamAddDefinition,
        setLoader,
        props.refresh
      );
    },
    [props.refresh, props.tableName]
  );

  return (
    <>
      <ProcessusProvider
        sjmoCode="CRM"
        tableName={props.tableName}
        selected={[{ id: props.entityId }]}
        isDirty={() => false}
        findEntities={findEntities}
      >
        <ProcessusMenu
          getProcessusDefinition={getProcessusDefinition}
          openAdvanced={openAdvanced}
          onSave={() => {}}
          t={tComet}
          delayedModal={<ProcessusDelayedModal key="processDelayModal" />}
          confirmationModal={<ProcessusConfirmationModal />}
          icon={<FontAwesomeIcon icon="ellipsis-v" />}
          buttonClassName={tw("text-white border-none py-0 bg(blue-600 hover:blue-700)")}
          open={menuOpen}
          onOpenChange={() => setMenuOpen(o => !o)}
        >
          {props.urls && (
            <div className={tw("p-2")}>
              <div className={tw("font-semibold text-center")}>
                <Trans i18nKey="crm_processus_menu_raccourcis">Raccourcis</Trans>
              </div>
              {props.urls.map((shortcut, index) => (
                <Menu.Item onSelect={() => navigate(shortcut.url)} key={index}>
                  <Trans i18nKey={shortcut.label} />
                </Menu.Item>
              ))}
            </div>
          )}
        </ProcessusMenu>
      </ProcessusProvider>
      {loader && (
        <div className={tw("absolute top-0 left-0 w-full h-full")} style={{ zIndex: 51 }}>
          <div className={tw("flex h-full justify-center items-center")}>
            <Loader />
          </div>
        </div>
      )}

      <ProcessussModal
        isOpen={paramAddDefinition !== null}
        definition={paramAddDefinition}
        onDismiss={() => setParamAddDefinition(null)}
        onValueChange={onChange}
        refresh={props.refresh}
      />
    </>
  );
};

export default ProcessusButton;
