import React, { FC, useMemo } from "react";
import { useQuery } from "react-query";
import BotTitle from "./templates/BotTitle";
import LeftTitle from "./templates/LeftTitle";
import ButtonLink from "component/menu/ButtonLink";
import { useUserSettings } from "hook/useUserSettings";
import { UserSettingProps } from "types/UserSettings";
import { api } from "api/api";
import { tw } from "twind";

const TEMPLATES = {
  BOT_TITLE: BotTitle,
  LEFT_TITLE: LeftTitle
};

interface IndicateurProps {
  viewName: string;
  title: string;
  template: "BOT_TITLE" | "LEFT_TITLE";
  filterUser?: boolean;
}

async function getData(viewName: string, q?: string) {
  const { data } = await api.compo.indicateur.getIndicateurDataBySource({
    sjmoCode: "CRM",
    sourceName: viewName,
    filter: q
  });
  return data;
}

const Indicateur: FC<IndicateurProps> = props => {
  const { id: user } = useUserSettings() as UserSettingProps;

  const q: string = useMemo(() => `userId=='${user}'`, [user]);

  const { data } = useQuery(
    [props.viewName, props.filterUser === true ? q : null],
    () => getData(props.viewName, props.filterUser === true ? q : undefined),
    {
      enabled: user !== undefined && user != null
    }
  );

  const SelectedComponent: any = TEMPLATES[props.template];

  return (
    <ButtonLink
      to={data?.url ?? "#"}
      className={tw("w-full bg(white hover:gray-50 focus:gray-50) outline-none")}
    >
      <SelectedComponent {...data} title={props.title} />
    </ButtonLink>
  );
};

export default Indicateur;
