import React, { useMemo, useCallback } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  useNavigate
} from "react-router-dom";
import useSearchParams from "hook/useSearchParams";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  Container,
  Stack,
  ProcessusManager,
  ProcessusAdvancedFormInitData,
  ProcessusJobStatus,
  ProcessusResult,
  NotificationGroup,
  NotificationIntent,
  NotificationPriority
} from "@axin-org/comet";
import { useTranslation } from "react-i18next";

import Home from "page/Accueil";

import "./css/body.css";
import { api } from "api/api";
import { Pojo } from "types/Generic";
import toaster from "component/toaster/toaster";
import { uuidv4 } from "component/utils/uuid.utils";
import { tw } from "twind";
import { PUBLIC_URL } from "customGlobal";

async function executeProcessus(
  module: string,
  compositeID: string,
  selected: Record<string, any>[],
  optional: {
    executeAt?: string;
    modeEdition?: string;
  }
): Promise<string> {
  const { data } = await api.processus.executeProcessus(
    module,
    compositeID,
    selected as Pojo[],
    optional
  );
  return data;
}

async function executeProcessusAdvanced(
  module: string,
  compositeID: string,
  formData: ProcessusAdvancedFormInitData,
  optional: {
    executeAt?: string;
    modeEdition?: string;
  }
): Promise<string> {
  const { data } = await api.processus.executeProcessusAdvanced(
    module,
    compositeID,
    formData,
    optional
  );
  return data;
}

async function checkProcessusExecution(jobID: string): Promise<ProcessusJobStatus> {
  const { data } = await api.processus.checkProcessusExecution(jobID);
  return data;
}

async function fetchProcessusResult(jobID: string): Promise<ProcessusResult> {
  const { data } = await api.processus.fetchProcessusResult(jobID);
  return data;
}

async function cancelProcessusResult(jobID: string): Promise<ProcessusJobStatus> {
  const { data } = await api.processus.cancelProcessusExecution(jobID);
  return data;
}

const FicheContact = React.lazy(() => import("page/contact/FicheContact"));
const ListeContact = React.lazy(() => import("page/contact/ListeContact"));
const FormulaireModificationDevis = React.lazy(() =>
  import("page/devis/FormulaireModificationDevis")
);
const FormulaireCreationDevis = React.lazy(() => import("page/devis/FormulaireCreationDevis"));
const FormulaireCreationContact = React.lazy(() =>
  import("page/contact/FormulaireCreationContact")
);
const FormulaireModificationContact = React.lazy(() =>
  import("page/contact/FormulaireModificationContact")
);
const FormulaireCreationAffaire = React.lazy(() =>
  import("page/affaire/FormulaireCreationAffaire")
);
const FormulaireModificationAffaire = React.lazy(() =>
  import("page/affaire/FormulaireModificationAffaire")
);
const ListeDevis = React.lazy(() => import("page/devis/ListeDevis"));
const ListeCommande = React.lazy(() => import("page/commande/ListeCommande"));
const ListeAffaire = React.lazy(() => import("page/affaire/ListeAffaire"));
const FormulaireCreationCommande = React.lazy(() =>
  import("page/commande/FormulaireCreationCommande")
);
const FormulaireModificationCommande = React.lazy(() =>
  import("page/commande/FormulaireModificationCommande")
);
const FormulaireCreationLigneDevisClient = React.lazy(() =>
  import("page/devis/FormulaireCreationLigneDevisClient")
);
const FormulaireModificationLigneDevisClient = React.lazy(() =>
  import("page/devis/FormulaireModificationLigneDevisClient")
);
const FormulaireModificationLigneCdeClient = React.lazy(() =>
  import("page/commande/FormulaireModificationLigneCdeClient")
);
const FormulaireCreationAffaireIntervenant = React.lazy(() =>
  import("page/affaire/FormulaireCreationAffaireIntervenant")
);
const ModificationAffaireIntervenantClient = React.lazy(() =>
  import("page/affaire/ModificationAffaireIntervenantClient")
);
const ModificationAffaireIntervenantPersonnel = React.lazy(() =>
  import("page/affaire/ModificationAffaireIntervenantPersonnel")
);
const Agenda = React.lazy(() => import("page/Agenda"));
const CreationActionCommerciale = React.lazy(() =>
  import("page/actionCommerciale/CreationActionCommerciale")
);
const ModificationActionCommerciale = React.lazy(() =>
  import("page/actionCommerciale/ModificationActionCommerciale")
);
const ModificationActionIntervenantClient = React.lazy(() =>
  import("page/actionCommerciale/ModificationActionIntervenantClient")
);
const ModificationActionIntervenantInterne = React.lazy(() =>
  import("page/actionCommerciale/ModificationActionIntervenantInterne")
);
const FormulaireCommentaire = React.lazy(() => import("page/commentaire/FormulaireCommentaire"));
const FicheDevis = React.lazy(() => import("page/devis/FicheDevis"));
const FicheLead = React.lazy(() => import("page/leads/FicheLead"));
const FicheCommande = React.lazy(() => import("page/commande/FicheCommande"));
const FicheAffaire = React.lazy(() => import("page/affaire/FicheAffaire"));
const FormulaireCreationLigneCdeClient = React.lazy(() =>
  import("page/commande/FormulaireCreationLigneCdeClient")
);
const FicheActionCommerciale = React.lazy(() =>
  import("page/actionCommerciale/FicheActionCommerciale")
);
const FormulaireCreationAffaireIntervenantInterne = React.lazy(() =>
  import("page/affaire/FormulaireCreationAffaireIntervenantInterne")
);

const DashboardClients = React.lazy(() => import("page/DashboardClients"));
const DashboardDeals = React.lazy(() => import("page/DashboardDeals"));
const DashboardLeads = React.lazy(() => import("page/DashboardLeads"));
const BottomBar = React.lazy(() => import("component/menu/BottomBar"));
const Search = React.lazy(() => import("page/Search"));
const FullPageLoader = React.lazy(() => import("component/utils/FullPageLoader"));
const FormulaireCreationDocument = React.lazy(() =>
  import("page/document/FormulaireCreationDocument")
);
const CreationActionIntervenantClient = React.lazy(() =>
  import("page/actionCommerciale/CreationActionIntervenantClient")
);
const CreationActionIntervenantInterne = React.lazy(() =>
  import("page/actionCommerciale/CreationActionIntervenantInterne")
);
const ListeActionCommerciale = React.lazy(() =>
  import("page/actionCommerciale/ListActionCommerciale")
);
const EditorForm = React.lazy(() => import("page/commentaire/EditorForm"));

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10_000,
      retry: false,
      refetchInterval: false,
      refetchOnWindowFocus: false
    }
  }
});

const basename = PUBLIC_URL() != "/" ? PUBLIC_URL().replace(/\/+$/, "") : PUBLIC_URL();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/dashboard/clients" element={<DashboardClients />} />
      <Route path="/dashboard/leads" element={<DashboardLeads />} />
      <Route path="/dashboard/deals" element={<DashboardDeals />} />
      <Route path="/agenda" element={<Agenda />} />
      <Route path="/search" element={<Search />} />
      <Route path="/search/:tab" element={<Search />} />

      <Route path="/list/clientContact" element={<ListeContact />} />
      <Route path="/clientContact/:id" element={<FicheContact />} />
      <Route
        path="/create/clientContact"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <FormulaireCreationContact />
          </React.Suspense>
        }
      />
      <Route
        path="/update/clientContact/:id"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <FormulaireModificationContact />
          </React.Suspense>
        }
      />

      <Route path="/lead/:id" element={<FicheLead />} />

      <Route path="/list/devisClient" element={<ListeDevis />} />
      <Route path="/devisClient/:id" element={<FicheDevis />} />
      <Route
        path="/create/devisClient"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <FormulaireCreationDevis />
          </React.Suspense>
        }
      />
      <Route
        path="/update/devisClient/:id"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <FormulaireModificationDevis />
          </React.Suspense>
        }
      />
      <Route
        path="/create/ligneDevisClient"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <FormulaireCreationLigneDevisClient />
          </React.Suspense>
        }
      />
      <Route
        path="/update/ligneDevisClient/:id"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <FormulaireModificationLigneDevisClient />
          </React.Suspense>
        }
      />

      <Route path="/list/cdeClient" element={<ListeCommande />} />
      <Route path="/cdeClient/:id" element={<FicheCommande />} />
      <Route
        path="/create/cdeClient"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <FormulaireCreationCommande />
          </React.Suspense>
        }
      />
      <Route
        path="/update/cdeClient/:id"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <FormulaireModificationCommande />
          </React.Suspense>
        }
      />
      <Route
        path="/create/ligneCdeClient"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <FormulaireCreationLigneCdeClient />
          </React.Suspense>
        }
      />
      <Route
        path="/update/ligneCdeClient/:id"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <FormulaireModificationLigneCdeClient />
          </React.Suspense>
        }
      />

      <Route path="/list/affaire" element={<ListeAffaire />} />
      <Route path="/affaire/:id" element={<FicheAffaire />} />
      <Route
        path="/create/affaire"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <FormulaireCreationAffaire />
          </React.Suspense>
        }
      />
      <Route
        path="/update/affaire/:id"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <FormulaireModificationAffaire />
          </React.Suspense>
        }
      />
      <Route
        path="/create/affaireIntervenant/client"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <FormulaireCreationAffaireIntervenant />
          </React.Suspense>
        }
      />
      <Route
        path="/create/affaireIntervenant/interne"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <FormulaireCreationAffaireIntervenantInterne />
          </React.Suspense>
        }
      />
      <Route
        path="/update/affaireIntervenant/client/:id"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <ModificationAffaireIntervenantClient />
          </React.Suspense>
        }
      />
      <Route
        path="/update/affaireIntervenant/personnel/:id"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <ModificationAffaireIntervenantPersonnel />
          </React.Suspense>
        }
      />

      <Route path="/list/actionCommerciale" element={<ListeActionCommerciale />} />
      <Route path="/actionCommerciale/:id" element={<FicheActionCommerciale />} />
      <Route
        path="/create/actionCommerciale"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <CreationActionCommerciale />
          </React.Suspense>
        }
      />
      <Route
        path="/update/actionCommerciale/:id"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <ModificationActionCommerciale />
          </React.Suspense>
        }
      />
      <Route
        path="/create/actionCommercialeIntervenant/client"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <CreationActionIntervenantClient />
          </React.Suspense>
        }
      />
      <Route
        path="/create/actionCommercialeIntervenant/personnel"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <CreationActionIntervenantInterne />
          </React.Suspense>
        }
      />
      <Route
        path="/update/actionCommercialeIntervenant/client/:id"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <ModificationActionIntervenantClient />
          </React.Suspense>
        }
      />
      <Route
        path="/update/actionCommercialeIntervenant/personnel/:id"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <ModificationActionIntervenantInterne />
          </React.Suspense>
        }
      />

      <Route
        path="/create/commentaire"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <FormulaireCommentaire />
          </React.Suspense>
        }
      />
      <Route
        path="/update/commentaire/:id"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <FormulaireCommentaire />
          </React.Suspense>
        }
      />
      <Route
        path=":tableName/:id/editor/:column"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <EditorForm />
          </React.Suspense>
        }
      />

      <Route
        path="/create/document"
        element={
          <React.Suspense fallback={<FullPageLoader />}>
            <FormulaireCreationDocument />
          </React.Suspense>
        }
      />
      <Route index element={<Home />} />
    </Route>
  ),
  {
    basename: basename
  }
);

export default function RouterRoot() {
  return <RouterProvider router={router} />;
}

function App() {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const notify = useCallback(
    (params: {
      key?: string;
      title?: string;
      group: NotificationGroup;
      intent: NotificationIntent;
      priority: NotificationPriority;
      createdAt: string;
    }) => {
      const { key, title, group, intent, priority, createdAt } = params;
      toaster.notify({
        id: uuidv4(),
        group,
        intent,
        priority,
        createdAt,
        title: key ? t(key) : (title as string)
      });
    },
    [t]
  );

  return (
    <ProcessusManager
      executeProcessus={executeProcessus}
      executeProcessusAdvanced={executeProcessusAdvanced}
      checkProcessusExecution={checkProcessusExecution}
      fetchProcessusResult={fetchProcessusResult}
      cancelProcessusResult={cancelProcessusResult}
      notify={notify}
      navigateTo={navigate}
    >
      <QueryClientProvider client={queryClient}>
        <React.Suspense
          fallback={
            <div style={{ height: "calc(100vh - 4rem)" }}>
              <Stack gap={2} className="h-full">
                <FullPageLoader />
                <div className={tw("text-xl items-center")}>{t("crm_chargement_page")}</div>
              </Stack>
            </div>
          }
        >
          <Container>
            <div style={{ height: "calc(100vh - 4rem)" }}>
              <Outlet />
            </div>
          </Container>
        </React.Suspense>
        <BottomBar />
      </QueryClientProvider>
    </ProcessusManager>
  );
}
