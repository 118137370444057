import React, { FC } from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import { tw } from "twind";

// const Link: ForwardRef<HTMLAnchorElement, LinkProps> = React.forwardRef(function Input(props, ref) {
//   return <CometLink ref={ref} as={RouterLink} {...props} />;
// });

const Link: FC<LinkProps & React.RefAttributes<HTMLAnchorElement>> = props => {
  const { className, ...restProps } = props;
  return (
    <RouterLink {...restProps} className={tw({ className })}>
      {props.children}
    </RouterLink>
  );
};

export default Link;
