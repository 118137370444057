import React, { FC, useState } from "react";
import { AvatarProps, Avatar } from "@axin-org/comet";
import { URL_DATA } from "customGlobal";
import auth from "auth";
import useRandomColor from "hook/useRandomColor";
import { tw } from "twind";

type AvatarCrmProps = AvatarProps & { tableName: string; id?: string };

const AvatarCrm: FC<AvatarCrmProps> = props => {
  const { src, alt, tableName, id, ...restProps } = props;
  const [isOk, setIsOk] = useState<boolean>(true);

  const color = useRandomColor(tableName, id);

  return (
    <>
      {isOk && id && (
        <Avatar
          style={{ height: "5rem", width: "5rem" }}
          src={`${URL_DATA()}/document/avatar?tableName=${tableName}&id=${encodeURIComponent(
            id
          )}&access_token=${auth.token}`}
          onError={() => setIsOk(false)}
          alt={alt}
          {...(restProps as any)}
        />
      )}
      {!isOk && (
        <div
          className={tw(
            "h-20 w-20 rounded-full shadow-inner flex justify-center items-center text-xs text-white"
          )}
          style={{ backgroundColor: color }}
          aria-hidden
          {...(restProps as any)}
        >
          <div className={tw("text-2xl")}>{alt?.substr(0, 2) ?? ""}</div>
        </div>
      )}
    </>
  );
};

export default AvatarCrm;
