import i18n from "i18next";
import Backend from "i18next-chained-backend";
import HttpApi, { BackendOptions as HttpBackendOptions } from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import { initReactI18next } from "react-i18next";

import { parse, differenceInDays, getHours, getMinutes, distanceInWordsToNow } from "date-fns";
import { getNumberFormat, getDateFormat, getUserLang } from "./network.utils";
import { IS_PRODUCTION } from "customGlobal";
import { api } from "api/api";

import fr from "date-fns/locale/fr";
import de from "date-fns/locale/de";
import en from "date-fns/locale/en";

export type availableLang = "fr" | "en" | "de";

const backendParam = {
  backends: [LocalStorageBackend, HttpApi],
  backendOptions: [
    {
      prefix: "i18next_res_",
      // en production, on met en cache les traductions pour la journée
      expirationTime: IS_PRODUCTION() ? 1 * 24 * 60 * 60 * 1000 : 15000
    },
    {
      // Définition de l'url du webservice qui permet d'aller chercher les traductions
      loadPath: "{{lng}}/{{ns}}",

      // Chemin où poster les ressouces manquantes
      // Inutilisé pour le moment
      addPath: "add/{{lng}}/{{ns}}",

      // Autorise la chargement de plusieurs namesapces en parrallèle
      // On en a un seul donc false suffit
      allowMultiLoading: false,

      // Définition de la méthode qui charge les ressources statiques
      request: function(options, url, payload, callback) {
        api.translation
          .loadTranslations(url)
          .then(res => {
            const result = { status: res.status, data: res.data };
            // callback attend un objet json valide et le status de la réponse
            callback(null, result);
          })
          .catch(err => {
            callback(err, { status: err.response?.status ?? 500, data: "" });
          });
      }
    } as HttpBackendOptions
  ]
};

export function initI18n() {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      lng: "fr",
      fallbackLng: "fr",
      ns: ["crm"], // Les namespaces définit ici sont chargés à l'ouverture de l'application
      defaultNS: "crm",
      debug: false,
      interpolation: {
        escapeValue: false, // false car pas utile avec react
        format: (value, format) => {
          switch (format) {
            case "number": {
              if (typeof value === "number") {
                return formatNumber(value);
              }

              return value;
            }

            case "date":
              return formatDate(value);

            case "dateTime":
              return formatDateTime(value);

            case "dateRelative":
              return formatDateRelative(value);

            case "dateAuto":
              return formatDateAuto(value);

            default:
              return value;
          }
        }
      },
      pluralSeparator: "#", // on redéfinit cette constante afin de garder le "_" comme séparateur dans notre clé
      contextSeparator: ";", // on redéfinit cette constante afin de garder le "_" comme séparateur dans notre clé,
      backend: backendParam,
      saveMissing: !IS_PRODUCTION,
      missingKeyHandler: (lng, ns, key, fallbackValue) => {
        if (key !== fallbackValue) {
          // api.post(`/translation/new`, {
          //   key,
          //   value: fallbackValue
          // });
        }
      }
    });

  return i18n;
}

export default i18n;

/**
 * Permet de formatter une date en fonction de la locale courante.
 *
 * @export
 * @param {(Date | string | undefined | null)} date
 * @returns
 */
export function formatDate(
  date: Date | string | undefined | null,
  options?: Intl.DateTimeFormatOptions
) {
  if (!date) {
    return null;
  }

  const parsedDate = parse(date);

  const dateFormat = getDateFormat(options);
  return dateFormat.format(parsedDate);
}

export function formatDateTime(date: Date | string | undefined | null) {
  if (!date) {
    return null;
  }

  const parsedDate = parse(date);

  if (getHours(parsedDate) === 0 && getMinutes(parsedDate) === 0) {
    return formatDate(date, {
      day: "numeric",
      month: "long",
      year: "numeric"
    });
  }

  return formatDate(date, {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  });
}

export function formatDateRelative(date: Date | string | undefined | null, includeSeconds = false) {
  if (!date) {
    return null;
  }

  const parsedDate = parse(date);

  const locale = getUserLocaleDateFns();
  return distanceInWordsToNow(parsedDate, { includeSeconds, locale });
}

export function formatDateAuto(date: Date | string | undefined | null) {
  if (!date) return null;

  const parsedDate = parse(date);
  if (differenceInDays(new Date(), parsedDate) < 7) {
    return formatDateRelative(date);
  }

  return formatDateTime(date);
}

/**
 * Permet de formatter un nombre vers une représentation en string correcte en fonction de la locale utilisateur
 *
 * @export
 * @param {number} nb nombre à formatter
 * @returns retourne
 */
export function formatNumber(nb: number | null, options?: Intl.NumberFormatOptions) {
  if (nb === null) {
    return "";
  }

  let currentFormatter = getNumberFormat(options);
  return currentFormatter.format(nb);
}

export function parseLocaleNumber(stringNumber: string | null, options?: Intl.NumberFormatOptions) {
  if (stringNumber === null) {
    return null;
  }
  let numberFormat = getNumberFormat(options);

  var thousandSeparator = numberFormat.format(1111).replace(/1/g, "");
  var decimalSeparator = numberFormat.format(1.1).replace(/1/g, "");

  return parseFloat(
    stringNumber
      .replace(new RegExp("\\" + thousandSeparator, "g"), "")
      .replace(new RegExp("\\" + decimalSeparator), ".")
  );
}

export const LOCALE = {
  fr,
  de,
  en
};

export function getUserLocaleDateFns(): any {
  const lang = getUserLang();

  return LOCALE[lang];
}

/**
 * Permet, grâce à Intl.DateTimeFormat, de déterminer le pattern utilisé pour une locale
 * On passe le résultat de getUserLang dans le premier paramètre si on souhaite avoir le pattern de l'utilisateur courant.
 * Merci stackoverflow : https://stackoverflow.com/a/54115358
 * @param language langage pour générer le pattern
 */
export function getDatePattern(language: string) {
  const sample = Intl ? new Intl.DateTimeFormat(language).format(new Date(1970, 11, 31)) : "";

  let mm = 0,
    mi = sample.indexOf("12");
  let dd = 1,
    di = sample.indexOf("31");
  let yy = 2,
    yi = sample.indexOf("1970");

  // IE 10 or earlier, iOS 9 or earlier, non-Latin numbering system
  // or non-Gregorian calendar; fall back to mm/dd/yyyy
  if (yi >= 0 && mi >= 0 && di >= 0) {
    mm = (mi > yi ? 1 : 0) + (mi > di ? 1 : 0);
    dd = (di > yi ? 1 : 0) + (di > mi ? 1 : 0);
    yy = (yi > mi ? 1 : 0) + (yi > di ? 1 : 0);
  }

  let format = [];
  format[yy] = "YYYY";
  format[mm] = "MM";
  format[dd] = "DD";

  return format.join((sample.match(/[-.]/) as any) || "/");
}

/**
 * Permet de vérifier si la date passé correspond au pattern que l'on a positionné.
 * Ne prend pas en compte les heures
 *
 * @param date date string
 * @param pattern pattern à vérifier
 */
export function dateMatchPattern(date: string, pattern: string): boolean {
  let regex = pattern.replace("DD", "\\d\\d");
  regex = regex.replace("MM", "\\d\\d");
  regex = regex.replace("YYYY", "\\d\\d\\d\\d");

  const regexDateFromUserLocale = new RegExp(regex);

  // si le pattern n'est pas valide pour les utilisateurs, on vérifie qu'il correspond bien au pattern ISO 8601
  return date.match(regexDateFromUserLocale) != null;
}

/**
 * Permet de reconvertir une date sous un pattern d'un locale spécifique vers un pattern ISO.
 * Ne prend pas en compte les heures
 *
 * @param date date string
 * @param pattern pattern to parse
 */
export function parseDateFromPattern(date: string, pattern: string): string | null {
  let patternToUse = pattern.replace("DD", "(?<day>\\d\\d)");
  patternToUse = patternToUse.replace("MM", "(?<month>\\d\\d)");
  patternToUse = patternToUse.replace("YYYY", "(?<year>\\d\\d\\d\\d)");

  const regExp = new RegExp(patternToUse);
  const parts = regExp.exec(date);
  if (parts === null || parts.groups === undefined) {
    return null;
  }

  const { day, month, year } = parts.groups;

  return `${year}-${month}-${day}`;
}
