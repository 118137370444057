import { init } from "@axin-org/api";
import { COOKIE_TOKEN } from "auth";
import { URL_ADN, URL_DATA, URL_TEMPLATE_SERVER, URL_UTILS } from "customGlobal";
import Cookie from "js-cookie";

export const api = init({
  baseUrl: {
    rest: URL_DATA() as string,
    adn: URL_ADN() as string,
    utils: URL_UTILS() as string,
    template: URL_TEMPLATE_SERVER() as string
  },
  async getToken() {
    return Cookie.get(COOKIE_TOKEN) as string;
  },
  lang: "fr",
  handleError(err) {
    console.error("err api", err);
  }
});
