import React, { FC, useState, useEffect } from "react";
import { Notification, NotificationPriority } from "types/Notification";
import Toast from "./Toast";

import "./toast.css";
import produce from "immer";

const NOTIFICATION_PRIORITY_OVERLAY_DURATION: Record<NotificationPriority, number> = {
  TEMPORARY: 0,
  LOW: 0,
  NORMAL: 2,
  HIGH: 5,
  CRITICAL: 100000
};

const ToastManager: FC<{
  bindNotify(handler: Function): void;
  bindCloseAll(handler: Function): void;
}> = ({ bindNotify, bindCloseAll }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    bindNotify(notify);
  }, [bindNotify]);

  useEffect(() => {
    bindCloseAll(closeAll);
  }, [bindCloseAll]);

  function notify(notification: Notification) {
    setNotifications(old => {
      const index = old.findIndex(n => n.id === notification.id);
      if (index !== -1) {
        return produce(old, draft => {
          draft[index] = notification;
        });
      }
      return [notification, ...old];
    });
  }

  function closeAll() {
    setNotifications([]);
  }

  function filterNotification(id: string) {
    setNotifications(old => old.filter(notif => notif.id !== id));
  }

  return (
    <span className="toast-container">
      <div className="flex flex-col">
        {notifications.map(notif => {
          return (
            <Toast
              key={notif.id}
              intent={notif.intent}
              title={notif.title}
              onRemove={() => filterNotification(notif.id)}
              duration={NOTIFICATION_PRIORITY_OVERLAY_DURATION[notif.priority]}
            >
              {notif.message}
            </Toast>
          );
        })}
      </div>
    </span>
  );
};

export default ToastManager;
