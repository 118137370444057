import { Columns } from "@axin-org/comet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api } from "api/api";
import React, { FC } from "react";
import { useQuery } from "react-query";

async function getVersion() {
  const { data } = await api.userSettings.getVersion();
  return data;
}

const Version: FC<{}> = props => {
  const { data: version } = useQuery("version", getVersion);

  return (
    <Columns gap={2} className="ml-5">
      <div>
        <FontAwesomeIcon icon={"code-branch"} />
      </div>
      <div>{version}</div>
    </Columns>
  );
};

export default Version;
