import React, { FC } from "react";
import { IndicateurData } from "types/IndicateurData";
import { Columns } from "@axin-org/comet";
import { Trans } from "react-i18next";
import { tw } from "twind";

const LeftTitle: FC<IndicateurData> = props => {
  return (
    <div title={props.tooltip}>
      <Columns gap={2} className="py-2 px-1">
        <div className={tw("text-black")}>
          <Trans i18nKey={props.title} />
        </div>
        <div className={tw(`text-[${props.color}]`)}>{props.value}</div>
      </Columns>
    </div>
  );
};

export default LeftTitle;
