import { ProcessusDefinition } from "@axin-org/comet";
import { api } from "api/api";

export async function getProcessusDefinition(
  sjmoCode: string,
  tableName: string
): Promise<ProcessusDefinition[]> {
  const { data } = await api.processus.getProcessusDefinition(sjmoCode, tableName);
  return data;
}
