import React, { FC, useRef, useState, useEffect, useCallback } from "react";
// vite want it to avoid a crash
import "@fullcalendar/react/dist/vdom";
import "@fullcalendar/core";
import FullCalendar, { DatesSetArg, EventClickArg } from "@fullcalendar/react";
import french from "@fullcalendar/core/locales/fr";
import english from "@fullcalendar/core/locales/en-gb";
import deutsch from "@fullcalendar/core/locales/de";
import italian from "@fullcalendar/core/locales/it";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import { EventContentArg } from "@fullcalendar/react";

import randomColor from "randomcolor";
import { Task } from "types/Scheduler";
import { useQuery } from "react-query";
import { isEqual, format, parse, compareAsc } from "date-fns";

import { useUserSettings } from "hook/useUserSettings";
import { UserSettingProps } from "types/UserSettings";
import { getAccoIcon } from "component/list/card/ActionCommercialeCard";

// css
import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "./fullCalendarContainer.css";

import { api } from "api/api";
import { initRsqlCriteria } from "utils/query.utils";
import { Operators, RSQLFilterExpression } from "rsql-criteria-typescript";
import { tw } from "twind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useButton } from "@react-aria/button";
import { AriaButtonProps } from "@react-types/button";
import { useNavigate } from "react-router-dom";

const plugins = [dayGridPlugin, timeGridPlugin, interactionPlugin] as any;

type AvailableLocale = "fr" | "en" | "de" | "it";
export type AvailableTimeSpan = "DAY" | "WEEK" | "MONTH";

const localeByLangCode = {
  fr: french,
  en: english,
  de: deutsch,
  it: italian
};

const CALENDAR_VIEW_BY_TIME_SPAN = {
  DAY: { view: "timeGridDay", duration: { hour: 1 } },
  WEEK: { view: "timeGridWeek", duration: { day: 1 } },
  MONTH: { view: "dayGridMonth", duration: { week: 1 } }
};

const EventRender: FC<EventContentArg> = props => {
  const task = props.event;
  const { icon, intent } = getAccoIcon(task.end as Date, task.extendedProps.statut);

  return (
    <div
      className={tw(
        "flex flex-wrap content-between w-full h-full overflow-hidden rounded",
        `bg-${task.backgroundColor} text-white`
      )}
    >
      <div dangerouslySetInnerHTML={{ __html: props.event.title }} />
      <div className={tw("py-1 bg-white flex justify-center w-full self-end rounded-b", intent)}>
        {icon && <FontAwesomeIcon icon={icon} />}
      </div>
    </div>
  );
};

export async function fetchTasks(user: string, start?: Date, end?: Date) {
  if (start && end) {
    const userFilter = initRsqlCriteria();
    userFilter.filters.and(new RSQLFilterExpression("resourceId", Operators.Equal, user));
    const { data } = await api.compo.scheduler.getSchedulerTasks({
      sjmoCode: "CRM",
      source: "vjCrmAgenda",
      start,
      end,
      interactionFilter: userFilter
    });
    for (const event of data) {
      event.backgroundColor = event.extendedProps.backgroundColor
        ? event.extendedProps.backgroundColor
        : "stone-900";
    }
    return data.sort((t1, t2) => compareAsc(parse(t1.start as string), parse(t2.start as string)));
  }
  return [];
}

const FullCalendarContainer: FC<{
  timeSpan: AvailableTimeSpan;
  setTimespan(tme: AvailableTimeSpan): void;
}> = props => {
  const calendarRef = useRef<FullCalendar | null>(null);
  const navigate = useNavigate();
  const { id: user, lang: locale } = useUserSettings() as UserSettingProps;

  const [pagination, setPagination] = useState<{ start: Date | undefined; end: Date | undefined }>({
    start: undefined,
    end: undefined
  });

  const { data: tasks } = useQuery([user, pagination.start, pagination.end], () =>
    fetchTasks(user, pagination.start, pagination.end)
  );

  useEffect(
    () =>
      calendarRef?.current?.getApi().changeView(CALENDAR_VIEW_BY_TIME_SPAN[props.timeSpan].view),
    [props.timeSpan]
  );

  const onDateChange = useCallback(
    (info: DatesSetArg) => {
      // On ne met à jour le state que si les valeurs changent vraiment, car les dates renvoyées sont de nouvelles instances à chaque fois
      if (
        !pagination.start ||
        !pagination.end ||
        !isEqual(info.view.activeStart, pagination.start) ||
        !isEqual(info.view.activeEnd, pagination.end)
      ) {
        setPagination({
          start: info.view.activeStart,
          end: info.view.activeEnd
        });
      }
    },
    [pagination, setPagination]
  );

  const dateClick = useCallback(
    (arg: DateClickArg): void => {
      navigate(
        `/create/actionCommerciale?accoDtPrevueJour=${encodeURIComponent(format(arg.date))}`
      );
    },
    [navigate]
  );

  const eventClick = useCallback(
    (arg: EventClickArg): boolean | void => {
      navigate(`/actionCommerciale/${encodeURIComponent(arg.event.id)}`);
    },
    [navigate]
  );

  return (
    <FullCalendar
      ref={calendarRef}
      nowIndicator
      headerToolbar={{
        left: "prev",
        center: "title",
        right: "next"
      }}
      locale={localeByLangCode[locale as AvailableLocale]}
      plugins={plugins}
      weekNumbers={true}
      events={(tasks ? tasks : []) as Task[]}
      height="100%"
      displayEventTime={false}
      datesSet={onDateChange}
      scrollTime="06:00:00"
      dateClick={dateClick}
      eventClick={eventClick}
      eventContent={EventRender}
      allDaySlot={false}
      dayHeaderContent={({ date, text, view }) => (
        <HeaderCalendar
          onPress={() => {
            if (view.type === "timeGridWeek") {
              props.setTimespan("DAY");
              calendarRef.current?.getApi().changeView("dayGridDay", date);
            }
          }}
        >
          {text}
        </HeaderCalendar>
      )}
    />
  );
};

function HeaderCalendar(props: AriaButtonProps) {
  const ref = useRef<HTMLSpanElement | null>(null);
  const { buttonProps } = useButton({ ...props, elementType: "span" }, ref);

  return (
    <span ref={ref} {...buttonProps}>
      {props.children}
    </span>
  );
}

export default FullCalendarContainer;
