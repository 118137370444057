const globals = {
  URL_DATA: import.meta.env.VITE_REST_URL,
  URL_ADN: import.meta.env.VITE_REST_URL_ADN,
  URL_UTILS: import.meta.env.VITE_REST_URL_UTILS,
  URL_WEBSOCKET: import.meta.env.VITE_WEBSOCKET_URL,
  URL_TEMPLATE_SERVER: import.meta.env.VITE_URL_TEMPLATE_SERVER,
  OAUTH_URL: import.meta.env.VITE_OAUTH_URL,
  REALM: import.meta.env.VITE_OAUTH_REALM || "octal",
  CLIENT_ID: "app:crm",
  ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT,
  NODE_ENV: import.meta.env.NODE_ENV,
  IS_PRODUCTION: import.meta.env.NODE_ENV === "production",
  PUBLIC_URL: import.meta.env.BASE_URL || "/",
  LOADER_TIME_TRIGGER: 1000,
  LOADER_GALAXY_TIME_TRIGGER: 2000,
  DATATABLE_PRE_RECORD_ENTITY_CACHE: 5 * 60 * 1000
};

export const URL_DATA = () => globals.URL_DATA;
export const URL_ADN = () => globals.URL_ADN;
export const URL_UTILS = () => globals.URL_UTILS;
export const URL_WEBSOCKET = () => globals.URL_WEBSOCKET;
export const URL_TEMPLATE_SERVER = () => globals.URL_TEMPLATE_SERVER;

export const OAUTH_URL = () => globals.OAUTH_URL;
export const REALM = () => globals.REALM;
export const CLIENT_ID = () => globals.CLIENT_ID;

export const ENVIRONMENT = () => globals.ENVIRONMENT;

export const NODE_ENV = () => globals.NODE_ENV;

export const IS_PRODUCTION = () => globals.NODE_ENV === "production";

export const PUBLIC_URL = () => globals.PUBLIC_URL;

export const LOADER_TIME_TRIGGER = () => globals.LOADER_TIME_TRIGGER;
export const LOADER_GALAXY_TIME_TRIGGER = () => globals.LOADER_GALAXY_TIME_TRIGGER;

// maximum de 5 minutes pour le cache du pre-record par datatable
export const DATATABLE_PRE_RECORD_ENTITY_CACHE = () => globals.DATATABLE_PRE_RECORD_ENTITY_CACHE;
