import React, { FC, useState, useCallback, useMemo } from "react";
import { Columns, Column, Input, Menu, Button, ButtonGroup } from "@axin-org/comet";
import ProcessusButton, { Shortcut } from "./ProcessusButton";
import ButtonLink from "./ButtonLink";
import { convertValue } from "utils/entities.utils";
import { Trans } from "react-i18next";
import { disconnect } from "auth";
import { useUserSettings } from "hook/useUserSettings";
import { UserSettingProps } from "types/UserSettings";
import DebugIcon from "./DebugIcon";
import SelectLang from "component/form/SelectLang";
import Version from "./Version";
import { tw } from "twind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bg_blue } from "constant/color";
import { useNavigate } from "react-router-dom";

const DashboardHeader: FC<{ urls?: Shortcut[]; titleKey?: string; searchTab?: string }> = props => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [modeDebug, setModeDebug] = useState<boolean>(
    sessionStorage.getItem("modeDebug") === "true"
  );

  const { id: userId, nom, prenom, adminLevel, superUser } = useUserSettings() as UserSettingProps;
  const userName = useMemo(() => {
    return `${prenom} ${nom}`;
  }, [nom, prenom]);

  const onChange = useCallback((e: React.SyntheticEvent<HTMLInputElement>) => {
    setSearchTerm(convertValue(e));
  }, []);

  const onSubmit = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        navigate(`/search/${props.searchTab ? props.searchTab : "contact"}?term=${searchTerm}`);
      }
    },
    [navigate, props.searchTab, searchTerm]
  );

  const switchModeDebug = useCallback(() => {
    const newValue = sessionStorage.getItem("modeDebug") !== "true";
    setModeDebug(newValue);
    sessionStorage.setItem("modeDebug", newValue.toString());
  }, []);

  return (
    <div>
      <Columns className={tw("text-white", bg_blue)}>
        <Column className="pl-2 pt-1" width="1/3">
          <strong>{userName}</strong>
        </Column>
        <Column width="1/3" style={{ alignItems: "center", justifyContent: "center" }}>
          <strong>
            <Trans i18nKey={props.titleKey}></Trans>
          </strong>
        </Column>
        <Column width="1/3">
          <div className={tw("flex justify-end w-full")}>
            <DebugIcon activated={modeDebug} />
            <ProcessusButton tableName="personnel" entityId={userId ?? ""} urls={props.urls} />
            <Menu>
              <Menu.Trigger>
                <Button intent="primary">
                  <FontAwesomeIcon icon="user" />
                </Button>
              </Menu.Trigger>
              <Menu.Content>
                <SelectLang />

                {adminLevel === 3 && superUser && (
                  <Menu.Item onSelect={switchModeDebug}>
                    <Columns gap={2}>
                      <div>
                        <FontAwesomeIcon icon="bug" />
                      </div>
                      <div>
                        <Trans i18nKey="crm_dashboard_mode-debug">Mode-debug</Trans>
                      </div>
                    </Columns>
                  </Menu.Item>
                )}

                <Menu.Separator />

                <Version />

                <Menu.Item onSelect={disconnect}>
                  <Columns gap={2}>
                    <div>
                      <FontAwesomeIcon icon="power-off" />
                    </div>
                    <div>
                      <Trans i18nKey="crm_dashboard_disconnect">Déconnecter</Trans>
                    </div>
                  </Columns>
                </Menu.Item>
              </Menu.Content>
            </Menu>
          </div>
        </Column>
      </Columns>
      <div className={tw(bg_blue)}>
        <div className={tw("p-1")}>
          {props.searchTab && (
            <ButtonGroup className="w-full">
              <Input
                type="text"
                title="search"
                onChange={onChange}
                onKeyPress={onSubmit}
                value={searchTerm}
              />
              <ButtonLink
                to={`/search/${props.searchTab ? props.searchTab : "contact"}?term=${searchTerm}`}
                style={{
                  backgroundColor: "white",
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8
                }}
              >
                <FontAwesomeIcon icon="search" />
              </ButtonLink>
            </ButtonGroup>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
