import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useMemo } from "react";
import { tw } from "twind";

/**
 * Composant qui affiche l'icon du mode débug activé.
 * Deux cas d'utilisation :
 *  - DashboardHeader, le seul composant qui peut changer la valeur de modeDebug en session storage. DebugIcon est directement piloter par dashboard header.
 *  - Autre topBar, on prend simplement la valeur via le session storage en sachant qu'elle ne bougera pas tant que le composant est render.
 *
 * @param props
 */
const DebugIcon: FC<{ activated?: boolean }> = props => {
  const modeDebug = useMemo(
    () =>
      props.activated !== undefined
        ? props.activated
        : sessionStorage.getItem("modeDebug") === "true",
    [props.activated]
  );

  return (
    <>
      {modeDebug && (
        <div>
          <FontAwesomeIcon icon="bug" className={tw("text-gray-700")} />
        </div>
      )}
    </>
  );
};

export default DebugIcon;
