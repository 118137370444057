import React, { FC } from "react";

/**
 * Ce composant doit exister car il est attendu par le processus manager
 * cependant il n'est pas implémenter car il ne sera jamais appelé.
 * La CRM mobile est découpée de façon à ce qu'il est impossible de lancer un processus sur des données dirty
 * @param props
 */
const ProcessusConfirmationModal: FC<{}> = props => {
  return <div>nulle</div>;
};

export default ProcessusConfirmationModal;
