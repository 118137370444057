import { availableLang } from "./i18n";

export const LANG = "octal-user-lang";

export function getUserLang() {
  const userLang = sessionStorage.getItem("userLang") as availableLang;

  return userLang !== null ? userLang : "fr";
}

export function getUserId() {
  return sessionStorage.getItem("userId");
}

export function getDateFormat(options?: Intl.DateTimeFormatOptions) {
  return new Intl.DateTimeFormat(getUserLang(), options);
}

export function getNumberFormat(options?: Intl.NumberFormatOptions): Intl.NumberFormat {
  return new Intl.NumberFormat(getUserLang(), {
    maximumFractionDigits: 2,
    ...options
  });
}

export function getCollator(options?: Intl.CollatorOptions): Intl.Collator {
  return new Intl.Collator(getUserLang(), options);
}

/**
 *  Permet de chainer des promesses en passant le resultat de chacune dans la promesses suivante
 *
 * @param firstValue valeur pour la première prosesse
 * @param promises liste de fonction lançant des promesses
 * @returns resultat de la dernière promesse
 */
export async function chainPromise<T>(firstValue: T, promises: ((param: T) => Promise<T>)[]) {
  let lastResult = firstValue;
  for (let promise of promises) {
    lastResult = await promise(lastResult);
  }
  return lastResult;
}

export async function chainPromiseAll<T>(promises: (() => Promise<T>)[]) {
  let results = [];
  for (let promise of promises) {
    let res = await promise();
    results.push(res);
  }

  return results;
}
