import React, { FC, useCallback, useState } from "react";
import {
  Stack,
  Button,
  Loader,
  useRegisterProcessus,
  ButtonGroup,
  Modal,
  FormFields,
  Form,
  FormElement
} from "@axin-org/comet";

import { Pojo } from "types/Generic";
import { Trans } from "react-i18next";
import { QueryObserverResult, RefetchOptions } from "react-query";
import { AdvancedProcessusDefinition } from "types/Processus";
import { tw } from "twind";
import { ProcessusComponentState } from "@axin-org/api";
import { useDefineGsFactory } from "hook/useDefineGs";

const ProcessussModal: FC<{
  isOpen: boolean;
  definition: AdvancedProcessusDefinition | null;
  onDismiss: () => void;
  onValueChange: (ctrlKey: string, value: string) => void;
  refresh?: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<Pojo, unknown>>;
}> = props => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [, { register: registerProcessus }] = useRegisterProcessus();
  const [paramsAdd, setParamsAdd] = useState<Record<string, any>[]>([]);
  const [index, setIndex] = useState(0);
  const gsFactory = useDefineGsFactory();

  const manageCompoDef = useCallback(
    (compos: ProcessusComponentState[]): FormElement[] => {
      return compos.map(compo => {
        if (compo.typeCompo === "GS") {
          return gsFactory({
            column: compo.column,
            joinTableName: compo.joinTableName,
            wvi: false,
            mandatory: compo.mandatory,
            label: compo.label,
            searchFields: compo.joinListFields.split(","),
            additionalClause: compo.additionalClause
          }) as FormElement;
        } else {
          return {
            typeCompo: compo.typeCompo,
            column: compo.column,
            label: compo.label,
            wvi: false,
            mandatory: compo.mandatory
          } as FormElement;
        }
      });
    },
    [gsFactory]
  );

  const launchProcess = useCallback(
    (currentParamAdd: Record<string, any>) => {
      if (props.definition) {
        if (index + 1 === props.definition.definition.data.paramsAdd.length) {
          setDisabled(true);
          registerProcessus(
            {
              module: "CRM",
              compositeID: props.definition.id,
              type: props.definition.nature as any,
              selected: props.definition.definition.data.entities ?? [],
              paramsAdd: [...paramsAdd, currentParamAdd],
              label: "",
              editionType: props.definition.nature === "EDITS" ? "rapide" : undefined
            },
            () => {
              setDisabled(false);
              props.refresh && props.refresh();
            }
          );
        } else {
          setParamsAdd(old => [...old, currentParamAdd]);
          setIndex(i => i + 1);
        }
      }
      return Promise.resolve(currentParamAdd) as any;
    },
    [index, paramsAdd, props, registerProcessus]
  );

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onDismiss}
      aria-labelledby={props.definition?.id}
      withOverlay={true}
    >
      <Stack gap={2}>
        <Trans id={props.definition?.id} i18nKey="crm_processus_modale_title" />
        {props.definition &&
          props.definition.definition.definitions.map(process => (
            <Form
              initialValues={
                props.definition ? (props.definition.definition.data.paramsAdd[0] as Pojo) : {}
              }
              onSubmit={launchProcess}
              onValidate={() => Promise.resolve({}) as any}
            >
              <Stack gap={2}>
                {process.map((def, index) => (
                  <div className={tw("bg-white rounded border border-gray-300 p-3")} key={index}>
                    {def.groupLabel && <label>{def.groupLabel}</label>}

                    <FormFields definition={manageCompoDef(def.compos)} />
                  </div>
                ))}
                <ButtonGroup>
                  <Button type="submit" intent="success" disabled={disabled}>
                    <Trans i18nKey="crm_valider" />
                  </Button>
                  <Button onClick={props.onDismiss} disabled={disabled}>
                    <Trans i18nKey="crm_annuler" />
                  </Button>
                </ButtonGroup>
              </Stack>
            </Form>
          ))}
        {disabled && <Loader />}
      </Stack>
    </Modal>
  );
};

export default ProcessussModal;
