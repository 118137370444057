import React, { FC, useMemo } from "react";
import { Pojo } from "types/Generic";
import { Columns, Column, Stack } from "@axin-org/comet";
import { format, parse } from "date-fns";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import AvatarCrm from "component/utils/AvatarCrm";
import Link from "component/Link";
import { tw } from "twind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { text_green, text_red } from "constant/color";

export const actionCommercialeCardHeight = 116;

export function getAccoIcon(date: Date, statut: string) {
  const isClosed = statut === "CLO";
  let icon: IconProp;
  let intent: string;

  if (isClosed) {
    icon = "check";
    intent = text_green;
  } else if (date < new Date()) {
    icon = "calendar";
    intent = text_red;
  } else {
    icon = "calendar";
    intent = text_green;
  }

  return { icon, intent };
}

const ActionCommercialeCard: FC<{ acco?: Pojo | null; fixedHeight?: boolean }> = props => {
  const { acco } = props;

  const { icon, intent } = useMemo(() => {
    if (acco) {
      return getAccoIcon(parse(acco.accoDtFin), acco.accoStatut);
    } else return { icon: "calendar" as IconProp, intent: "success" };
  }, [acco]);

  return (
    <div
      className={tw("p-3 bg-white mb-1", props.fixedHeight && `h-[${actionCommercialeCardHeight}]`)}
    >
      <Columns>
        <Column width="1/4">
          <Link
            to={`/actionCommerciale/${acco ? encodeURIComponent(acco.id) : ""}`}
            style={{ textDecoration: "none" }}
          >
            <AvatarCrm
              tableName="client"
              id={acco?.clientId?.id ?? ""}
              alt={acco?.clientId?.clntNom ?? ""}
            />
          </Link>
        </Column>
        <Column width="3/4">
          <Stack className="w-full">
            <div>{acco?.accoDesignation ? acco.accoDesignation : ""}</div>
            <div>{acco?.clientId?.clntNom ?? ""}</div>
            <Columns>
              <Column width="4/5" className="items-center">
                {`${acco ? format(acco.accoDtPrevueJour, "DD/MM/YYYY HH:mm") : ""}`}
                <FontAwesomeIcon icon="arrow-right" className={tw("text-blue-500 mx-2")} />
                {`${acco ? format(acco.accoDtFin, "DD/MM/YYYY HH:mm") : ""}`}
              </Column>
              <Column width="1/5">
                <div>{icon && <FontAwesomeIcon icon={icon} className={tw(intent)} />}</div>
              </Column>
            </Columns>
          </Stack>
        </Column>
      </Columns>
    </div>
  );
};

export default ActionCommercialeCard;
