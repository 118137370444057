import randomColor from "randomcolor";
import { useMemo } from "react";

export default function useRandomColor(tableName: string, id?: string) {
  const color = useMemo(
    () =>
      randomColor({
        seed: `${tableName}-${id}`,
        luminosity: "dark",
        format: "hsla",
        alpha: 0.8
      }),
    [id, tableName]
  );
  return color;
}
