import React, { useState, createContext, useContext, FC, useMemo, useCallback } from "react";
import { UserSettingProps } from "types/UserSettings";

const UserContext = createContext<{
  settings: UserSettingProps | null;
  set(params: UserSettingProps): void;
}>({
  settings: null,
  set() {}
});

export const UserContextProvider: FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, setState] = useState<UserSettingProps | null>(null);

  const set = useCallback((value: UserSettingProps) => {
    setState(value);
  }, []);

  const context = useMemo(() => {
    return {
      settings: state,
      set
    };
  }, [set, state]);

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
};

export function useUserSettings() {
  const userSettings = useContext(UserContext);
  return userSettings.settings;
}

export function useInitializeUserSettings() {
  const userSettings = useContext(UserContext);
  return userSettings.set;
}
